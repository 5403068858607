<template>
  <div>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基本资料</el-breadcrumb-item>
      <el-breadcrumb-item>客户等级</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.cl_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addcustomerlevelDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="customerlevellist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="等级名称" prop="cl_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="折扣率" prop="cl_discount" show-overflow-tooltip></el-table-column>
        <el-table-column label="备注" prop="cl_remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" size="mini" icon="el-icon-edit"
                       @click="showEditDialog(scope.row.cl_id)" v-if="is_edit"></el-button>
            <!--删除-->
            <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                       @click="removeWareById(scope.row.cl_id)" v-if="is_del"></el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!--  添加对话框  -->
    <el-dialog
              title="添加客户等级" :visible.sync="addcustomerlevelDialogVisible" width="50%" @close="addcustomerlevelFormDialogClose">
      <el-form :model="addcustomerlevelForm" :rules="addcustomerlevelFormRules" ref="addcustomerlevelFormRef" label-width="150px">
        <el-form-item label="等级名称：" prop="cl_name">
          <el-input v-model="addcustomerlevelForm.cl_name"></el-input>
        </el-form-item>
        <el-form-item label="折扣率：" prop="cl_discount">
          <el-input v-model="addcustomerlevelForm.cl_discount"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="cl_remark">
          <el-input v-model="addcustomerlevelForm.cl_remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addcustomerlevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addcustomerlevel" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!--  编辑对话框  -->
    <el-dialog
        title="编辑客户等级" :visible.sync="editcustomerlevelDialogVisible" width="50%" @close="editcustomerlevelFormDialogClose">
      <el-form :model="editcustomerlevelForm" :rules="editcustomerlevelFormRules" ref="editcustomerlevelFormRef" label-width="150px">
        <el-form-item label="等级名称：" prop="cl_name">
          <el-input v-model="editcustomerlevelForm.cl_name"></el-input>
        </el-form-item>
        <el-form-item label="折扣率：" prop="cl_discount">
          <el-input v-model="editcustomerlevelForm.cl_discount"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="cl_remark">
          <el-input v-model="editcustomerlevelForm.cl_remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editcustomerlevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editcustomerlevel" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkcl_discount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('折扣率不能为空'));
      }
      // setTimeout(() => {
      //   if (!Number.isInteger(value)) {
      //     callback(new Error('请输入正确的折扣率'));
      //   } else {
      //     if (value < 0) {
      //       callback(new Error('折扣率必须大于0'));
      //     } else {
      //       callback();
      //     }
      //   }
      // }, 500);
    };
    return{
      addloading:false,
      editloading:false,
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        cl_name:''
      },
      //页面权限
      customerlevelJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      total:0,
      // 获取客户信息数据
      customerlevellist:[],
      // 添加客户信息数据
      addcustomerlevelForm:{
        cl_name:'',
        cl_discount:'',
        cl_remark:''
      },
      // 获取编辑客户信息数据
      editcustomerlevelForm: {},
      //控制添加对话框的显示与隐藏
      addcustomerlevelDialogVisible:false,
      //控制编辑对话框的显示与隐藏
      editcustomerlevelDialogVisible:false,
      //添加表单的验证规则对象
      addcustomerlevelFormRules: {
        cl_name: [
          {required: true, message: '请输入等级名称', trigger: 'blur'},
        ],
        cl_discount: [
          // {required: true,validator: checkcl_discount, trigger: 'blur'}
          {required: true, message: '请输入折扣率', trigger: 'blur'}

        ],
        // cl_remark: [
        //   {required: true, message: '请输入备注', trigger: 'blur'},
        // ]
      },
      editcustomerlevelFormRules: {
        cl_name: [
          {required: true, message: '请输入等级名称', trigger: 'blur'},
        ],
        cl_discount: [
          {required: true, message: '请输入折扣率', trigger: 'blur'}
        ],
        // cl_remark: [
        //   {required: true, message: '请输入备注', trigger: 'blur'},
        // ]
      },
    }
  },
  created() {
    this.getcustomerlevelJurisdiction()
    this.getcustomerlevelList()
  },
  activated(){
    this.getcustomerlevelList()
  },
  methods: {
    //获取权限接口
    async getcustomerlevelJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.customerlevelJurisdiction=res.data
      this.customerlevelJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    //获取分页数据
    async getcustomerlevelList() {
      this.loading=true
      const {data: res} = await this.$http.get('customerlevel/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.customerlevellist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getcustomerlevelList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getcustomerlevelList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getcustomerlevelList()
    },
    //监听
    addcustomerlevelFormDialogClose(){
      this.addcustomerlevelForm.cl_name=''
      this.addcustomerlevelForm.cl_discount=''
      this.addcustomerlevelForm.cl_remark=''
      this.$refs.addcustomerlevelFormRef.resetFields()
    },
    editcustomerlevelFormDialogClose(){
      this.$refs.editcustomerlevelFormRef.resetFields()

    },
    //点击添加显示添加对话框
    addcustomerlevelDialog(){
      this.addcustomerlevelDialogVisible=true
    },
    //点击确定提交表单
    async addcustomerlevel(){
      this.$refs.addcustomerlevelFormRef.validate(async valid => {
      if (!valid) return
      this.addloading=true
      const {data: res} = await this.$http.post('customerlevel/add',this.addcustomerlevelForm)
      this.addloading=false
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.addcustomerlevelDialogVisible=false
      this.getcustomerlevelList()
      })
    },
    //点击编辑查找当前数据
    async showEditDialog(id){
      const {data:res} = await this.$http.get(`customerlevel/find/${id}`)
      if (res.code!=200) return this.$message.error(res.msg)
      // this.$message.success(res.msg)
      this.editcustomerlevelForm=res.data
      this.editcustomerlevelDialogVisible=true
    },
    //点击确定提交表单 customerlevel/edit
    async editcustomerlevel(){
      this.$refs.editcustomerlevelFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data: res} = await this.$http.post(`customerlevel/edit`, this.editcustomerlevelForm)
        this.editloading=false
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.editcustomerlevelDialogVisible = false
        this.getcustomerlevelList()
      })
    },
    //删除 goodsunit/del/12
    async removeWareById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该客户等级, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`customerlevel/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getcustomerlevelList()

    }

  }
}
</script>
